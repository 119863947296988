import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import { Card, Stack, Typography, Button } from "@mui/material";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
// Component Imports
import RewardModal from "./RewardModal";

const RewardCard = ({
  rewardTitle,
  rewardBody,
  rewardImage,
  moduleLessons,
  rewardVideo,
  rewardURL,
  rewardText,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [unlocked, setUnlocked] = useState(false);

  // FOR UNLOCKING MODAL
  const lessonArray = moduleLessons.map((nested) =>
    nested.lesson.map((lesson) => lesson._ref)
  );
  const lessonRefArray = [].concat.apply([], lessonArray);

  const completedLessons = useSelector(
    (store) => store.authenticated.completedLessons
  )
    .map((lesson) => lesson.lessonReference)
    .filter((lesson) => lessonRefArray.includes(lesson));

  useEffect(() => {
    const percentage = (completedLessons.length / lessonRefArray.length) * 100;
    if (percentage === 100) {
      setUnlocked(true);
    }
  }, [completedLessons.length, lessonRefArray.length]);

  return (
    <Card
      sx={{
        padding: 2,
        mx: "auto",
        width: "100%",
        maxWidth: "sm",
      }}
    >
      <Stack gap={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" fontWeight={400} fontSize="16px">
            Weekly reward
          </Typography>
        </Stack>
        <Typography variant="body1" fontWeight={500} fontSize="18px">
          Finish the week to unlock your reward!
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <EmojiEventsRoundedIcon fontSize="large" />
          {!!rewardTitle && (
            <Typography
              variant="subtitle"
              color="text.secondary"
              fontWeight={400}
              fontSize={14}
            >
              {rewardTitle}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" alignItems="flex-start" mt={1}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            sx={{ width: 90 }}
            onClick={() => setOpenModal(true)}
            disabled={!unlocked}
          >
            {unlocked ? "Claim" : "Locked"}
          </Button>
        </Stack>
      </Stack>
      <RewardModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rewardImage={rewardImage}
        rewardVideo={rewardVideo}
        rewardText={rewardText}
      />
    </Card>
  );
};

export default RewardCard;
