import React, { useEffect } from "react";

// MUI Imports
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
// Component Imports
import PublicHeader from "../../components/public/PublicHeader";
import PageFooter from "../../components/global/PageFooter";
import ScrollToTop from "../../components/global/ScrollToTop";
// Styling Imports
import { darkMode } from "../../styledcomponents/themeoptions";
import { PageTitle, PageSubtitle } from "../../styledcomponents/typography";
import { BackgroundBox } from "../../styledcomponents/containers";

const MentorForm = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js";

    script.async = true;

    // script.integrity =
    //   "sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"

    script.crossOrigin = "anonymous";

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={darkMode}>
      <BackgroundBox
        sx={{ bgcolor: "background.default", color: "text.primary" }}
      >
        <PublicHeader />
        <Container maxWidth="lg">
          <PageTitle variant="h2" component="h1">
            Availability
          </PageTitle>
          <PageSubtitle variant="h3" component="h2">
            Fill out the form below to let us know when you are available to
            mentor in the coming month.
          </PageSubtitle>
          <Container maxWidth="md">
            <iframe
              className="airtable-embed airtable-dynamic-height"
              src="https://airtable.com/embed/shrfQHdAzHmfnQjAb?backgroundColor=orange"
              frameBorder="0"
              // onmousewheel=""
              width="100%"
              height="1660"
              style={{ background: "transparent", border: "transparent" }}
              title="Airtable Form"
            ></iframe>
          </Container>
          <PageFooter />
        </Container>
      </BackgroundBox>
      <ScrollToTop />
    </ThemeProvider>
  );
};

export default MentorForm;
