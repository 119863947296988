import React, { useEffect } from "react";

// MUI Imports
import { ThemeProvider } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
// Component Imports
import PublicHeader from "../../components/public/PublicHeader";
import PageFooter from "../../components/global/PageFooter";
import ScrollToTop from "../../components/global/ScrollToTop";
// Styling Imports
import styled from "styled-components/macro";
import { darkMode } from "../../styledcomponents/themeoptions";
import { PageTitle, PageSubtitle } from "../../styledcomponents/typography";
import { BackgroundBox } from "../../styledcomponents/containers";
// Component Import
import LoadingIndicator from "../../components/global/LoadingIndicator";
import Newsletter from "../../components/public/Newsletter";
// Function import
import { FetchResponse } from "../../services/clientFunctions";
// Query Declaration
const pageQuery = `*[_type == "settings" && !(_id in path('drafts.**'))] {registrationToggle, closedText}`;

const RegistrationPage = () => {
  const [loading, response] = FetchResponse(pageQuery);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js";

    script.async = true;

    // script.integrity =
    //   "sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"

    script.crossOrigin = "anonymous";

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={darkMode}>
      <BackgroundBox
        sx={{ bgcolor: "background.default", color: "text.primary" }}
      >
        <PublicHeader />
        <Container maxWidth="lg">
          <PageTitle variant="h2" component="h1">
            Register today!
          </PageTitle>
          <PageSubtitle variant="h3" component="h2">
            ✨ Ready to start the journey? ✨
          </PageSubtitle>
          <Container maxWidth="md">
            {loading && <LoadingIndicator />}
            {!loading && response[0].registrationToggle && (
              <iframe
                className="airtable-embed airtable-dynamic-height"
                src="https://airtable.com/embed/shr47SxmeUvfNMb7l?backgroundColor=green"
                frameBorder="0"
                // onmousewheel=""
                width="100%"
                height="1943"
                style={{ background: "transparent", border: "transparent" }}
                title="Airtable Form"
              ></iframe>
            )}
            {!loading && !response[0].registrationToggle && (
              <>
                <ClosedText>{response[0].closedText}</ClosedText>
                <Newsletter />
              </>
            )}
          </Container>
          <PageFooter />
        </Container>
      </BackgroundBox>
      <ScrollToTop />
    </ThemeProvider>
  );
};

export default RegistrationPage;

const ClosedText = styled(Typography)`
  white-space: pre-line;
  padding-bottom: 16px;
`;
