import React from "react";
import styled from "styled-components";
import { urlFor } from "../../client";
import ReactPlayer from "react-player";
import { PortableText } from "@portabletext/react";

import myPortableTextComponents from "../../styledcomponents/myPortableTextComponents";

// MUI Imports
import { Box, Typography, Modal } from "@mui/material";
import { FrameDiv } from "../../styledcomponents/containers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "80%",
  bgcolor: "background.default",
  color: "text.primary",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const RewardModal = ({
  openModal,
  setOpenModal,
  rewardImage,
  rewardVideo,
  rewardText,
}) => {
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            component="h2"
            fontWeight={400}
            align="center"
            sx={{ mb: 2 }}
          >
            Congratulations!
          </Typography>
          <ImageContainer>
            {!!rewardImage && (
              <RewardImage src={urlFor(rewardImage.asset._ref).url()} />
            )}
          </ImageContainer>
          {!!rewardVideo && (
            <FrameDiv>
              <ReactPlayer
                url={rewardVideo}
                controls={true}
                width="100%"
                height="100%"
                className="react-player"
              />
            </FrameDiv>
          )}
          <BodyText sx={{ mt: 2 }}>
            <PortableText
              value={rewardText}
              components={myPortableTextComponents}
            />
          </BodyText>
        </Box>
      </Modal>
    </>
  );
};

export default RewardModal;

const BodyText = styled(Typography)`
  white-space: pre-line;
  margin-top: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RewardImage = styled.img`
  /* width: 334px; */
  height: auto;
  width: 200px;
`;
